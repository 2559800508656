import { DEFAULT_LANGUAGE, langCodes } from './constants';

export const getApp = () => global.window.__jfrouter?.ACTIVE_ALIAS || global.window.location.pathname?.replace(/\//g, '');
export const isProduction = () => global.window?.__jfrouter?.JOTFORM_ENV === 'PRODUCTION' || global.window?.JOTFORM_ENV === 'PRODUCTION';

const encodedAndProcessedStrings = [];

const processTranslation = async string => {
  const transformPhrase = string?.replace(/JotForm|Jform/gm, 'Jotform')?.trim();
  const buffer = new TextEncoder('utf-8').encode(transformPhrase);
  const digest = await global.crypto.subtle.digest('SHA-1', buffer);
  return {
    raw: transformPhrase,
    encoded: Array.from(new Uint8Array(digest)).map(x => x.toString(16).padStart(2, '0')).join('')
  };
};

export const encodeUsedTranslations = translations => {
  translations.forEach(translation => {
    processTranslation(translation)
      .then(({ encoded, raw }) => {
        if (!global.Translations.encodedAndProcessedStrings.some(q => Object.keys(q).includes(encoded))) {
          global.Translations.encodedAndProcessedStrings.push({
            [encoded]: raw
          });
        }
      })
      .catch(error => console.error(error));
  });

  return encodedAndProcessedStrings;
};

export const collectionTimer = () => {
  const year = new Date().getFullYear();
  const month = new Date().getMonth();
  const day = new Date().getDate();
  const hours = new Date().getHours();
  const minutes = new Date().getMinutes();
  const now = Date.UTC(year, month, day, hours, minutes, 0);
  const startDate = Date.UTC(year, month, day, hours, 0, 0);
  const endDate = Date.UTC(year, month, day, hours, 15, 0);

  // Between HH:00 and HH:15
  return startDate < now && endDate > now;
};

export const unlocalizeOriginHref = url => {
  const shortLangCodes = Object.keys(langCodes)?.join('|');
  const exp = RegExp(`(\\/)(${shortLangCodes})(\\/)`, 'g');

  return url?.replace(exp, '/');
};

export const getLanguageText = langCode => new Intl
  .DisplayNames([DEFAULT_LANGUAGE], {
    type: 'language'
  })
  .of(langCode);

export const getLanguageFlag = region => (
  region.replace(/./g, ch => String.fromCodePoint(0x1f1a5 + ch.toUpperCase().charCodeAt(0)))
);
