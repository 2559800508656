import { translation } from './Translation';
import { initializeTranslationFromGlobal } from './global';
import { prepareT, prepareTranslationRenderer, prepareTDate } from './hof';
import { langCodes, getLangFileSuffix } from './constants';
import { getLanguageText, getLanguageFlag } from './utils';
import { TranslateProvider } from './components/TranslateProvider';
import { setDatepickerLocale } from './setDatepickerLocale';

const t = prepareT(translation);
const tdate = prepareTDate(translation);
const translationRenderer = prepareTranslationRenderer(t);

initializeTranslationFromGlobal(translation, t);

export {
  t,
  tdate,
  translation,
  translationRenderer,
  langCodes,
  getLangFileSuffix,
  TranslateProvider,
  setDatepickerLocale,
  getLanguageText,
  getLanguageFlag
};

export default translation;
